import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaHeart, FaPhone, FaStar } from 'react-icons/fa';
import {Link, useNavigate} from "react-router-dom";
import "./index.css";
import { FiLoader, FiStar } from 'react-icons/fi';
import { APIURL, APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { Button, Modal } from 'react-bootstrap';
import { FavoritesContext, UserContext } from '../../contexts';
import CustomButton from '../CustomButton';
const Product = ({product,badgeShown=true}) => {
    const [userData,setUserData] = useContext(UserContext);
    const [favorites,setFavorites] = useContext(FavoritesContext);
    const [modalShown,setModalShown] = useState(false);
    const [like,setLike] = useState({
        loading:false,
        error:false,
        message:""
    });
    const [imageLoaded,setImageLoaded] = useState(false);
    const imageRef = useRef();
    const navigate = useNavigate();

    const isLiked = item => {
    for (let i = 0; i < favorites.length; i++) {
      if (item.id == favorites[i].id) {
        return true;
      }
    }
    return false;
  };

  const fetchFavorites = ()=>{
    fetch(`${APPDATAURLS.userFavorites}${userData.data.id}`,{
        method:"GET"
    }).then(req=>req.json()).then(response=>{
        setFavorites(response.data)
    }).catch(err=>{
        console.log(err);
    })
  }

  const likeProduct = item => {
    console.log(item);
    if (!userData.loggedIn) {
        setLike({
            error:true,
            loading:false,
            message:"Login first to add product to favorites."
        })
        setModalShown(true);
      return;
    }

    let formData = new FormData();
    formData.append('add-like', 'add-like');
    formData.append('productID', item.id);
    formData.append('userID', userData.data.id);
    setLike({
      loading: true,
      message: '',
      error: false,
    });
    fetch(APIURL, {
      method: 'POST',
      body: formData,
    })
      .then(req => req.json())
      .then(response => {
        console.log(response);
        setLike({
          loading: false,
          message: response.message,
          error: response.error,
        });
        fetchFavorites();
      })
      .catch(err => {
        setLike({
          loading: false,
          message: err.message,
          error: true,
        });
      });
  };

  const removeLike = item => {
    if (!userData.loggedIn) {
      setLike({
            error:true,
            loading:false,
            message:"Login first to add product to favorites."
        })
        setModalShown(true);
      return;
    }
    let formData = new FormData();
    formData.append('remove-like', 'remove-like');
    formData.append('productID', item.id);
    formData.append('userID', userData.data.id);
    setLike({
      loading: true,
      message: '',
      error: false,
    });
    fetch(APIURL, {
      method: 'POST',
      body: formData,
    })
      .then(req => req.text())
      .then(response => {
        console.log(response);
        setLike({
          loading: false,
          message: response.message,
          error: response.error,
        });
        fetchFavorites();
      })
      .catch(err => {
        setLike({
          loading: false,
          message: err.message,
          error: true,
        });
      });
  };

  const loadImage = async (url)=>{
    return new Promise((resolve,reject)=>{
      imageRef.current.onload = ()=>resolve(imageRef);
      imageRef.current.onerror =reject;
      imageRef.current.src = url;
    })
  }
  useEffect(()=>{
    
  },[]);
  return (
    <Link
    to={`/products/${product.productName.split("/").join(".")}/${product.id}`} 
    className='banner-product'
    
    >
        <Modal
        show={modalShown}
        centered
        >
            <Modal.Body>
              {like.message}
            </Modal.Body>
            <Modal.Footer>
              <Button
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                navigate("/login");
                setModalShown(false);
              }}
              variant='primary'
              >Login now</Button>
              <Button
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                setModalShown(false);
              }}
              variant='info'
              >Close</Button>
            </Modal.Footer>
        </Modal>
        <div
            className='banner-image'
        >
            <img
              style={{
                display:imageLoaded?"flex":"none",
              }}
                src={`${BASEURL}/${product.productImage}`}
                ref={imageRef}
                onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}}
                onLoadStart={(e)=>{
                }}
                onLoadedData={(e)=>{
                }}
            />

            <img
              style={{
                display:imageLoaded?"none":"flex",
              }}
            className='default'
            src='/assets/images/savo_logo.jpg'
            />
            <div

            onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                if(userData.loggedIn){
                    if(isLiked(product)){
                        removeLike(product);
                    }else{
                        likeProduct(product);
                    }
                }else{
                    setLike({
                        loading:false,
                        message:"You are logged out. Log in to like this product",
                        error:true,
                    })
                    setModalShown(true);
                }
            }}
            style={{
                position:"absolute",
                width:30,
                height:30,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                backgroundColor:"#ffffff",
                right:5,
                borderRadius:15,
                bottom:-15,
                boxShadow: "2px 2px 2px #f6f6f6",
                zIndex:10,
            }}
            >
                {
                  like.loading?
                  <FiLoader
                    color={"var(--app-color)"} 
                    fill={"var(--app-color)"} 
                    size={13}
                  />:
                  <FaHeart 
                    color={userData.loggedIn?isLiked(product)?"var(--app-color)":"#b9b4b4":"#b9b4b4"} 
                    fill={userData.loggedIn?isLiked(product)?"var(--app-color)":"#b9b4b4":"#b9b4b4"} 
                    size={13}/>
                }
            </div>

            <div
            style={{
                position:"absolute",
                padding:4,
                display:badgeShown?"flex":'none',
                alignItems:"center",
                justifyContent:"center",
                backgroundColor:"var(--app-color)",
                color:"#ffffff",
                left:5,
                borderRadius:20,
                top:5,
                fontSize:12,
                width:"fit-content",
                height:"fit-content"
            }}
            >
                {product.hasDiscount?"Flash sale!!":"New"}
            </div>
        </div>
        

        <div className='description' >
            <label style={{
                gap:5,
                display:"flex",
                flexDirection:"row",
                marginLeft:5,
                marginTop:5
            }}  >
                    {
                        [1,2,3,4,5].map((item,index)=>{
                            return <FiStar key={index} color={item<=product.rating?'var(--app-color)':'#b9b4b4'}  fill={item<=product.rating?'var(--app-color)':'#b9b4b4'} size={15} />
                        })
                    }
                </label>
            <label
                className='productShop'
            >{product.companyName}</label>
            <label
                className='productName'
            >{product.productName.length>28?`${product.productName.substring(0,28)}...`:product.productName}</label>
            <div>
              <label
                  style={{
                    textDecoration:product.hasDiscount?"line-through":"none",
                    color:product.hasDiscount?"grey":""
                  }}
                  className='productPrice'
              >Ksh.{product.productPrice}</label>

              {
                product.hasDiscount?
                <label
                  className='productPrice'
              >Ksh.{product.priceAfterDiscount}</label>:<></>
              }
            </div>

            <div className='add-to-cart'>
              <CustomButton
                title={"Add to cart"}
                backgroundColor={"var(--app-color)"}
                width={"100%"}
              />
            </div>
           
        </div>
    </Link>
  )
}

export default Product;